<template>
	<main class="error-page">
		<h1 class="error-page__text">
			<span class="visually-hidden">
				Ошибка:
			</span>
			<span class="error-page__code">
				404
			</span>
			<span class="error-page__text-row">
				Страница
			</span>
			<span class="error-page__text-row error-page__text-row--text-indent">
				не найдена
			</span>
		</h1>
		<router-link
			class="button button--white button--back"
			:to="{ name: 'HolderHome' }"
		>
			На главную
		</router-link>
	</main>
</template>

<script>
export default {
	name: 'ErrorPage',

	data() {
		return {}
	},
}
</script>
